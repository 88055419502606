<template>
  <div>
    <van-nav-bar :title="$t('about_xvr')" :left-text="$t('back')" @click.left="$router.back()" />
    <div class="text-center py-5">
      <img :src="logo" />
      <p class="mt-4 text-muted">{{ $t('slogan') }}</p>
      <p>v1.0.0</p>
    </div>
  </div>
</template>

<script>
export default {
  setup() {

    const logo = require(process.env.VUE_APP_LOGO_DARK);
    return { logo }
  }
}
</script>

<style lang="less" scoped></style>